import { defineStore } from 'pinia';

// Global store to check if a component is displayed on preview
export const useIsPreviewStore = defineStore('isPreview', {
	state: () => ({
		isPreview: false,
	}),
	actions: {
		updateIsPreviewing(preview) {
			this.isPreview = preview;
		},
	},
});
